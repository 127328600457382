@import 'src/assets/scss/partials/variables';
@import "src/styles/_mixins";

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

body {
  padding-top: 0 !important;
}

.welcome-tile-text {
  padding: 0 0 .6rem .5rem;
  margin: 1rem;
  height: 94%;
  display: flex;
  align-items: end;
  font-size: 3rem;
  font-family: $font-family-myriad-light !important;

  line-height: 3.5rem !important;
  font-weight: 300;
  letter-spacing: -0.07px;

  @include md {
    top: 0.5rem;
    color: $white;
    font-size: 2rem;
  }
  @include sm {
    margin-top: 5rem;
    align-items: unset;
    top: 0.5rem;
    color: $white;
    font-size: 1.6rem;
    height: 100%;
  }
}

.mb-login-container {
  min-height: 100%;
  margin-bottom: -5rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include md {
    margin-top: -7rem;
  }

  @include sm {
    align-items: normal;
    margin-top: -5rem;
  }
}

.mb-login-container:after {
  content: '';
  display: block;
}

.mb-footer-container,
.mb-login-container:after {
  height: 5rem;
}

.mb-login-content {
  width: 63rem;
  display: flex;
  justify-content: center;
  margin-top: 12rem;
  //margin-bottom: 2rem;
  @include md {
    margin-top: 12rem;
    width: unset;
    flex-direction: column;
    margin: 1rem;
  }
  @include sm {
    margin-top: 12rem;
    width: unset;
    flex-direction: column;
    margin: 1rem;
  }
}

.info-tile-title {
  font-size: 1.3rem;
  padding-top: 0.6rem;
  @include sm {
    margin-left: 2rem;
  }
}

.welcome-tile {
  width: 35rem;
  aspect-ratio: 795 / 619;
  background-image: url("../assets/images/welcome_image.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: -7rem;
  @include md {
    background-image: url("../assets/images/Welcome_teaser_with_text_mobile.png");
  }
  @include sm {
    background-image: url("../assets/images/Welcome_teaser_with_text_mobile.png");
    width: 100%;
    margin-top: 5rem;
  }
}

.mb-login-login-tile {
  width: 35rem;
  aspect-ratio: 795 / 619;
  background-image: url("../assets/images/dashboard_form_background.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @include md {
    background-image: none;
    background-color: white;
    border-radius: 0.6rem;
    aspect-ratio: auto;
    margin-top: 1rem;
    width: 33rem;
  }
  @include sm {
    background-image: none;
    background-color: white;
    border-radius: 0.6rem;
    aspect-ratio: auto;
    margin-top: 1rem;
    width: 100%
  }
}

.mb-login-info-container {
  width: 63rem;
  background-color: #3C1292;
  border-radius: 0.625rem;
  color: $white;
  padding: 2rem 0 2rem 0;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;

  @include md {
    width: 29rem;
    flex-direction: column;
    padding: 2rem;
  }

  @include sm {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }
}

.mb-login-info-container-circle {
  background-color: $corporate-orange;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-image: url("../assets/images/checkmark.svg");
}

.mb-login-info-container-text {
  padding-top: 0.4rem;
}

.info-text-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  @include sm {
    margin-left: 2rem;
  }
}

.mb-login-login-tile {
  overflow: hidden !important;

  #content {
    margin-left: 6rem !important;
    margin-top: 1rem !important;
    margin-right: 3rem !important;
    @include md {
      margin-left: auto !important;;
    }
    @include sm {
      margin-left: auto !important;;
    }
  }
}

// GIGYA CLASSES
#content_content {
  width: unset !important;
  background-color: transparent;
  margin: 0 2rem 0 6rem;
  padding-top: 0 !important;

  .gigya-screen {
    padding-top: 0 !important;
    width: 100% !important;
  }

  @include md {
    margin-left: 2rem;
  }
  @include sm {
    margin-left: 2rem;
  }
}

h1.gigya-screen-caption {
  padding: 0;
  margin: 0 0 0 3rem !important;
  width: 100% !important;
  text-align: left !important;
  margin-left: 6.5rem !important;
  font-weight: bold !important;
  color: #3C1192 !important;
}

#content input {
  margin-bottom: 0;
  border-width: 1px !important;
}

#content input::placeholder {
  opacity: 1;
  color: black;
  font-size: 18px;
}

.gigya-input-submit {
  box-shadow: 3px 5px 10px #888888 !important;
}


.gigya-screen .gigya-composite-control.gigya-composite-control-submit {
  padding-bottom: 0.5rem !important;
}


@media (max-width: 1400px) {
  .mb-footer-container {
    position: relative !important;
  }
}