@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container {
    max-width: 1320px;
  }
}

body {
  padding-top: 0 !important;
}

.welcome-tile-text {
  height: 94%;
  letter-spacing: -.07px;
  align-items: end;
  margin: 1rem;
  padding: 0 0 .6rem .5rem;
  font-size: 3rem;
  font-weight: 300;
  display: flex;
  font-family: myriad-pro-light, sans-serif !important;
  line-height: 3.5rem !important;
}

@media (width <= 991px) {
  .welcome-tile-text {
    color: #fff;
    font-size: 2rem;
    top: .5rem;
  }
}

@media (width <= 767px) {
  .welcome-tile-text {
    align-items: unset;
    color: #fff;
    height: 100%;
    margin-top: 5rem;
    font-size: 1.6rem;
    top: .5rem;
  }
}

.mb-login-container {
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: -5rem;
  display: flex;
}

@media (width <= 991px) {
  .mb-login-container {
    margin-top: -7rem;
  }
}

@media (width <= 767px) {
  .mb-login-container {
    align-items: normal;
    margin-top: -5rem;
  }
}

.mb-login-container:after {
  content: "";
  display: block;
}

.mb-footer-container, .mb-login-container:after {
  height: 5rem;
}

.mb-login-content {
  width: 63rem;
  justify-content: center;
  margin-top: 12rem;
  display: flex;
}

@media (width <= 991px) {
  .mb-login-content {
    width: unset;
    flex-direction: column;
    margin: 1rem;
  }
}

@media (width <= 767px) {
  .mb-login-content {
    width: unset;
    flex-direction: column;
    margin: 1rem;
  }
}

.info-tile-title {
  padding-top: .6rem;
  font-size: 1.3rem;
}

@media (width <= 767px) {
  .info-tile-title {
    margin-left: 2rem;
  }
}

.welcome-tile {
  width: 35rem;
  aspect-ratio: 795 / 619;
  background-image: url("welcome_image.c9400f0a.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: -7rem;
}

@media (width <= 991px) {
  .welcome-tile {
    background-image: url("Welcome_teaser_with_text_mobile.c301812e.png");
  }
}

@media (width <= 767px) {
  .welcome-tile {
    width: 100%;
    background-image: url("Welcome_teaser_with_text_mobile.c301812e.png");
    margin-top: 5rem;
  }
}

.mb-login-login-tile {
  width: 35rem;
  aspect-ratio: 795 / 619;
  background-image: url("dashboard_form_background.0c52cbdb.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (width <= 991px) {
  .mb-login-login-tile {
    aspect-ratio: auto;
    width: 33rem;
    background-color: #fff;
    background-image: none;
    border-radius: .6rem;
    margin-top: 1rem;
  }
}

@media (width <= 767px) {
  .mb-login-login-tile {
    aspect-ratio: auto;
    width: 100%;
    background-color: #fff;
    background-image: none;
    border-radius: .6rem;
    margin-top: 1rem;
  }
}

.mb-login-info-container {
  width: 63rem;
  color: #fff;
  background-color: #3c1292;
  border-radius: .625rem;
  justify-content: space-around;
  margin-bottom: 2rem;
  padding: 2rem 0;
  font-size: 14px;
  display: flex;
}

@media (width <= 991px) {
  .mb-login-info-container {
    width: 29rem;
    flex-direction: column;
    padding: 2rem;
  }
}

@media (width <= 767px) {
  .mb-login-info-container {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }
}

.mb-login-info-container-circle {
  width: 2rem;
  height: 2rem;
  background-color: #ff7d00;
  background-image: url("checkmark.f55ec9bd.svg");
  border-radius: 50%;
  margin-right: .5rem;
}

.mb-login-info-container-text {
  padding-top: .4rem;
}

.info-text-wrapper {
  flex-direction: row;
  margin: .5rem;
  display: flex;
}

@media (width <= 767px) {
  .info-text-wrapper {
    margin-left: 2rem;
  }
}

.mb-login-login-tile {
  overflow: hidden !important;
}

.mb-login-login-tile #content {
  margin-top: 1rem !important;
  margin-left: 6rem !important;
  margin-right: 3rem !important;
}

@media (width <= 991px) {
  .mb-login-login-tile #content {
    margin-left: auto !important;
  }
}

@media (width <= 767px) {
  .mb-login-login-tile #content {
    margin-left: auto !important;
  }
}

#content_content {
  background-color: #0000;
  margin: 0 2rem 0 6rem;
  width: unset !important;
  padding-top: 0 !important;
}

#content_content .gigya-screen {
  width: 100% !important;
  padding-top: 0 !important;
}

@media (width <= 991px) {
  #content_content {
    margin-left: 2rem;
  }
}

@media (width <= 767px) {
  #content_content {
    margin-left: 2rem;
  }
}

h1.gigya-screen-caption {
  padding: 0;
  width: 100% !important;
  text-align: left !important;
  color: #3c1192 !important;
  margin: 0 0 0 6.5rem !important;
  font-weight: bold !important;
}

#content input {
  margin-bottom: 0;
  border-width: 1px !important;
}

#content input::placeholder {
  opacity: 1;
  color: #000;
  font-size: 18px;
}

.gigya-input-submit {
  box-shadow: 3px 5px 10px #888 !important;
}

.gigya-screen .gigya-composite-control.gigya-composite-control-submit {
  padding-bottom: .5rem !important;
}

@media (width <= 1400px) {
  .mb-footer-container {
    position: relative !important;
  }
}

/*# sourceMappingURL=index.d7af4b7d.css.map */
